import firebaseConfig from '../resources/firebaseConfig';
import { initializeApp } from 'firebase/app';
import { Auth, User, NextOrObserver, ErrorFn, getAuth, onAuthStateChanged } from 'firebase/auth';

export class FirebaseUtils {
    static setupFirebase(nextOrObserver: NextOrObserver<User>, errorFn?: ErrorFn): Auth {
        const firebaseApp = initializeApp(firebaseConfig);
        const firebaseAuth = getAuth(firebaseApp);

        onAuthStateChanged(firebaseAuth, nextOrObserver, errorFn);

        return firebaseAuth;
    }
}
